import cookie from 'react-cookies';
import './App.css';
import { Button, Card, Col, Collapse, Layout, Menu, Row, Space, Tag, message, Image, Modal, FloatButton } from 'antd';
import {
  SettingOutlined,
  LogoutOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useEffect, createContext } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Badge, Descriptions } from 'antd';
import type { DescriptionsProps } from 'antd';
const { Header } = Layout;

const fetchEvent = (url: RequestInfo | URL) =>
  fetch(url).then((res) => res.json().then((res) => res));

const items = [
  {
    label: cookie.load('username'),
    key: 'Menu',
    icon: <SettingOutlined />,
    children: [
      {
        label: '登出',
        key: 'Logout',
        icon: <LogoutOutlined />,
      },
    ],
  },
];

const PersonalInfo = () => {
  const n = useNavigate();
  const token = cookie.load('token');
  const { data, error, isLoading } = useSWR(
    'https://xiaoyi.kanosaikou.cn/getboundstat.php?token=' + token,
    fetchEvent,
    { refreshInterval: 3000 }
  );
  if (error) return <div>加载出错</div>;
  if (isLoading) return <LoadingOutlined />;
  const type = data.success;
  return (
    <>
      <Row
        align={'middle'}
        justify={'center'}
        style={{ marginTop: '10px' }}
      >
        <Col lg={14} md={18} sm={22} xs={23}>
          {type === 1 && <Card title="年度报告">
            <Row justify={'center'} align={'middle'}>
              <Col span={12}>
                <Button type='primary' onClick={() => { n('/personal') }}>查看我的年度报告</Button>
              </Col>
              <Col span={12}>
                <Button type='primary' onClick={() => { n('/xiaoyi') }}>查看小逸的年度报告</Button>
              </Col>
            </Row>
          </Card>}
          <br />
          <Card title="个人信息" extra={type === 1 && <a href="#">编辑</a>}>
            {type === 1 &&
              <>
                <Descriptions bordered items={[
                  {
                    key: '1',
                    label: '昵称',
                    children: data.namestatus === "1" ? data.name : '***',
                  },
                  {
                    key: '2',
                    label: '城市',
                    children: data.city,
                  },
                  {
                    key: '3',
                    label: '昵称状态',
                    children: <>
                      {data.namestatus === "-1" &&
                        <Tag bordered={false} color="error">
                          未通过
                        </Tag>
                      }
                      {data.namestatus === "1" &&
                        <Tag bordered={false} color="success">
                          已通过
                        </Tag>
                      }
                      {data.namestatus === "0" &&
                        <Tag bordered={false} color="processing">
                          待审核
                        </Tag>
                      }
                      {data.namestatus === "2" &&
                        <Tag bordered={false} color="processing">
                          待审核
                        </Tag>
                      }
                    </>,
                  },
                  {
                    key: '4',
                    label: '重抽卡',
                    children: data.card1,
                  },
                  {
                    key: '5',
                    label: '转移卡',
                    children: data.card2,
                  },
                  {
                    key: '6',
                    label: '复制卡',
                    children: data.card3,
                  },
                  {
                    key: '7',
                    label: '好感',
                    children: data.haogan,
                  },
                ]} />
              </>
            }
            {
              type === 2 &&
              <>
                还没有绑定QQ，无法查看信息和年度报告，请在小逸在的群里发送以下指令进行绑定：
                <Row justify={'center'} align={'middle'}>
                  <Col span={20}>
                    小逸绑定{data.uuid}
                  </Col>
                  <Col span={4}>
                    <CopyToClipboard text={"小逸绑定" + data.uuid} onCopy={() => { message.success('复制成功'); }}>
                      <Button type="primary">复制</Button>
                    </CopyToClipboard>
                  </Col>
                </Row>
              </>
            }
          </Card>
        </Col>
      </Row>
    </>
  );
};

type cikuData = {
  available: string;
  keyword: string;
  id: string;
  qq: string;
  qqgroup: string;
  reply: string;
};
const Context = createContext<string | null>(null);

const PersonalCikuItem = ({ data, canDelete }: { data: cikuData, canDelete: boolean }) => {
  const { mutate } = useSWRConfig();
  const [modal, contextHolder] = Modal.useModal();
  const config = {
    title: '提示',
    content: (
      <>
        <Context.Consumer>{(name) => `确认要删除对${name}的回复？`}</Context.Consumer>
      </>
    ),
    maskClosable: true,
    zIndex: 7,
    okText: '删除',
    cancelText: '取消',
  };
  const re = /.*\[CQ:image,file=(.*)\.image.*/;
  let reply = null;
  if (re.test(data.reply)) {
    const filename = (re.exec(data.reply) as RegExpExecArray)[1];
    reply = <Image
      width={200}
      src={`https://xiaoyi.kanosaikou.cn/images/${filename}.jpg`}
    />;
  } else {
    reply = data.reply;
  }
  return (
    <Context.Provider value={(data.available === "1" ? data.keyword : "***")}>
      <Collapse
        items={[
          {
            key: '1',
            label: (data.available === "1" ? data.keyword : "***") + ` (群${data.qqgroup})`,
            children: <Space direction="vertical">
              <div>
                <strong>状态：</strong>
                <>
                  {data.available === "-1" &&
                    <Tag bordered={false} color="error">
                      未通过
                    </Tag>
                  }
                  {data.available === "1" &&
                    <Tag bordered={false} color="success">
                      已通过
                    </Tag>
                  }
                  {data.available === "0" &&
                    <Tag bordered={false} color="processing">
                      待审核
                    </Tag>
                  }
                  {data.available === "2" &&
                    <Tag bordered={false} color="processing">
                      待审核
                    </Tag>
                  }
                </>
              </div>
              <div>
                <strong>回复：</strong>{data.available === "1" ? reply : '***'}
              </div>
              {canDelete && <Button type="primary" danger onClick={async () => {
                const confirmed = await modal.confirm(config);
                if (confirmed) {
                  fetch('https://xiaoyi.kanosaikou.cn/delciku.php', {
                    method: 'POST',
                    headers: new Headers({
                      "Access-Control-Allow-Origin": "*",
                      "Content-Type": "application/json;charset=UTF-8",
                    }),
                    body: JSON.stringify({ token: cookie.load('token'), cikuid: data.id }),
                  }).then((res) => res.json()).then((res) => {
                    if (res.success) {
                      message.success('删除成功');
                      mutate('https://xiaoyi.kanosaikou.cn/getciku.php?token=' + cookie.load('token'));
                    } else {
                      message.error(res.response);
                    }
                  });
                }
              }}>
                删除
              </Button>}
            </Space>,
          },
        ]}
      />
      {contextHolder}
    </Context.Provider>
  );
}

const PersonalCiku = () => {
  const token = cookie.load('token');
  const { data, error, isLoading } = useSWR(
    'https://xiaoyi.kanosaikou.cn/getciku.php?token=' + token,
    fetchEvent,
    { refreshInterval: 3000 }
  );
  if (error) return <div>加载出错</div>;
  if (isLoading) return <LoadingOutlined />;
  const type = data.success;
  return (
    <>
      <Row
        align={'middle'}
        justify={'center'}
        style={{ marginTop: '10px' }}
      >
        <Col lg={14} md={18} sm={22} xs={23}>
          <Card title="我的词库">
            {type === 1 &&
              <Space direction="vertical" style={{ width: '100%' }}>
                {data.response.map((item: cikuData, key: number) => {
                  return <PersonalCikuItem data={item} key={key} canDelete={true} />
                })}
              </Space>
            }
            {
              type === 2 &&
              <>
                绑定QQ后即可查看自定义词库
              </>
            }
            {
              type === 3 &&
              <>
                目前还没有自定义词库，可以尝试查看<a href='https://blog.kanosaikou.cn/archives/33/'>教学方式</a>
              </>
            }
          </Card>
        </Col>
      </Row>
    </>
  );
};

const GlobalCikuItem = ({ data }: { data: cikuData }) => {
  const re = /.*\[CQ:image,file=(.*)\.image.*/;
  let keyword = null;
  let reply = null;
  if (re.test(data.reply)) {
    const filename = (re.exec(data.reply) as RegExpExecArray)[1];
    reply = <Image
      width={200}
      src={`https://xiaoyi.kanosaikou.cn/images/${filename}.jpg`}
    />;
  } else {
    reply = data.reply;
  }
  if (re.test(data.keyword)) {
    const filename = (re.exec(data.keyword) as RegExpExecArray)[1];
    keyword = <Image
      width={100}
      src={`https://xiaoyi.kanosaikou.cn/images/${filename}.jpg`}
    />;
  } else {
    keyword = data.keyword;
  }
  return (
    <Collapse
      items={[
        {
          key: '1',
          label: keyword,
          children:
            <div>
              <strong>回复：</strong>{reply}
            </div>,
        },
      ]}
    />
  );
}

const GlobalCiku = () => {
  const { data, error, isLoading } = useSWR(
    'https://xiaoyi.kanosaikou.cn/getglobalciku.php',
    fetchEvent,
    { refreshInterval: 3000 }
  );
  if (error) return <div>加载出错</div>;
  if (isLoading) return <LoadingOutlined />;
  return (
    <>
      <Row
        align={'middle'}
        justify={'center'}
        style={{ marginTop: '10px' }}
      >
        <Col lg={14} md={18} sm={22} xs={23}>
          <Card title="全局词库">
            <Space direction="vertical" style={{ width: '100%' }}>
              {data.response.map((item: cikuData, key: number) => {
                return <GlobalCikuItem data={item} key={key} />
              })}
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const App = () => {

  const n = useNavigate();
  const handleMenu = (e: any) => {
    cookie.remove('token');
    n('/login');
  };
  useEffect(() => {
    const auth = cookie.load('token');
    if (auth === undefined) {
      n('/login');
    }
  }, []);
  return (
    <div className="App">
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
        className='bg-white'
      >
        <div className='demo-logo' />
        <Menu
          onClick={handleMenu}
          mode='horizontal'
          defaultSelectedKeys={['2']}
          items={items}
          style={{ minWidth: '200px', justifyContent: 'flex-end' }}
        />
      </Header>

      <PersonalInfo />
      <PersonalCiku />
      <GlobalCiku />
      <FloatButton.BackTop />
    </div>
  );
}

export default App;
