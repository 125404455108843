import cookie from 'react-cookies';
import Lottie from 'lottie-react';
import './App.css';
import { Card, Col, Layout, Menu, Row, Space, Statistic } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
    SettingOutlined,
    LogoutOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import useSWR from 'swr';
import CountUp from 'react-countup';
import chat from './chat.json'
import yunshi from './yunshi.json'
import ciku from './ciku.json'

const { Header } = Layout;
const formatter = (value: any) => <CountUp end={value} separator="," preserveValue />;
const fetchEvent = (url: RequestInfo | URL) =>
    fetch(url).then((res) => res.json().then((res) => res));

const items = [
    {
        label: cookie.load('username'),
        key: 'Menu',
        icon: <SettingOutlined />,
        children: [
            {
                label: '登出',
                key: 'Logout',
                icon: <LogoutOutlined />,
            },
        ],
    },
];

const Result = () => {
    const token = cookie.load('token');
    const { data, error, isLoading } = useSWR(
        'https://xiaoyi.kanosaikou.cn/getXiaoYiData.php?token=' + token,
        fetchEvent,
        { refreshInterval: 3000 }
    );
    if (error) return <div>加载出错</div>;
    if (isLoading) return <LoadingOutlined />;

    return (
        <>
            <Row
                align={'middle'}
                justify={'center'}
                style={{ marginTop: '10px' }}
            >
                <Col lg={14} md={18} sm={22} xs={23}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Card><strong>小逸的年度总结.jpg</strong></Card>
                        <Card title="人缘甚广">
                            <Row justify={'center'} align={'middle'}>
                                <Col lg={4} sm={4} xs={4}>
                                    <Lottie animationData={chat} loop />
                                </Col>
                                <Col lg={8} sm={20} xs={20}>
                                    <p>
                                        话多朋友多（，龙王天天有
                                    </p>
                                </Col>
                                <Col lg={4} sm={8} xs={8}>
                                    <Statistic title="累计用户" value={data.users} formatter={formatter} />
                                </Col>
                                <Col lg={4} sm={8} xs={8}>
                                    <Statistic title="发送消息" value={data.sent} formatter={formatter} />
                                </Col>
                                <Col lg={4} sm={8} xs={8}>
                                    <Statistic title="接收消息" value={data.receive} formatter={formatter} />
                                </Col>
                            </Row>
                        </Card>
                        <Card title="年度词库">
                            <Row justify={'center'} align={'middle'}>
                                <Col lg={4} sm={4} xs={4}>
                                    <Lottie animationData={ciku} loop />
                                </Col>
                                <Col lg={16} sm={12} xs={12}>
                                    <p>
                                        <h2>{data.most_ciku}</h2>
                                    </p>
                                    <p>
                                        叠词词，恶心心（bushi
                                    </p>
                                </Col>
                                <Col lg={4} sm={8} xs={8}>
                                    <Statistic title="触发" value={data.most_ciku_times} formatter={formatter} />
                                </Col>

                            </Row>
                        </Card>
                        <Card title="公平公正">
                            <Row justify={'center'} align={'middle'}>
                                <Col lg={4} sm={4} xs={4}>
                                    <Lottie animationData={yunshi} loop />
                                </Col>
                                <Col lg={8} sm={20} xs={20}>
                                    <p>
                                        运势都是随机的嘞
                                    </p>
                                </Col>
                                <Col lg={12} sm={24} xs={24}>
                                    <Row justify={'center'} align={'middle'}>
                                        <Col span={8}>
                                            <Statistic title="大吉" value={data['1']} formatter={formatter} />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="中吉" value={data['2']} formatter={formatter} />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="小吉" value={data['3']} formatter={formatter} />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="中平" value={data['4']} formatter={formatter} />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="凶" value={data['5']} formatter={formatter} />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="大凶" value={data['6']} formatter={formatter} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Space>
                </Col>
            </Row>
        </>
    );
}

const XiaoYiResult = () => {

    const n = useNavigate();
    const handleMenu = (e: any) => {
        cookie.remove('token');
        n('/login');
    };
    useEffect(() => {
        const auth = cookie.load('token');
        if (auth === undefined) {
            n('/login');
        }
    }, []);
    return (
        <div className="App">
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
                className='bg-white'
            >
                <div className='demo-logo' />
                <Menu
                    onClick={handleMenu}
                    mode='horizontal'
                    defaultSelectedKeys={['2']}
                    items={items}
                    style={{ minWidth: '200px', justifyContent: 'flex-end' }}
                />
            </Header>
            <Result />
        </div>
    );
}

export default XiaoYiResult;
