import cookie from 'react-cookies';
import Lottie from 'lottie-react';
import './App.css';
import { Card, Col, Layout, Menu, Row, Space, Statistic,Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
    SettingOutlined,
    LogoutOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import useSWR from 'swr';
import CountUp from 'react-countup';
import luck from './luck.json'
import typing from './typing.json'
import time from './time.json'
import game from './game.json'
const { Header } = Layout;
const formatter = (value: any) => <CountUp end={value} separator="," preserveValue />;
const fetchEvent = (url: RequestInfo | URL) =>
    fetch(url).then((res) => res.json().then((res) => res));

const items = [
    {
        label: cookie.load('username'),
        key: 'Menu',
        icon: <SettingOutlined />,
        children: [
            {
                label: '登出',
                key: 'Logout',
                icon: <LogoutOutlined />,
            },
        ],
    },
];

const Result = () => {
    const token = cookie.load('token');
    const { data, error, isLoading } = useSWR(
        'https://xiaoyi.kanosaikou.cn/getMyData.php?token=' + token,
        fetchEvent,
        { refreshInterval: 3000 }
    );
    if (error) return <div>加载出错</div>;
    if (isLoading) return <LoadingOutlined />;
    let name = '你';
    if (data.namestatus === '1') {
        name = data.nickname;
    }
    let yunshiData = [];
    const yunshiList = ["1", "2", "3", "4", "5", "6"];
    for (let i = 0; i < 6; i++) {
        if (data[yunshiList[i]] === undefined) {
            data[yunshiList[i]] = 0;
        } else {
            data[yunshiList[i]] = parseInt(data[yunshiList[i]]);
        }
        yunshiData[i] = data[yunshiList[i]];
    }
    if (data.yunshicard === undefined) {
        data.yunshicard = 0;
    }
    if (data.dieci === undefined) {
        data.dieci = 0;
    }
    if (data.morning === undefined) {
        data.morning = 0;
    }
    if (data.night === undefined) {
        data.night = 0;
    }
    if (data.music === undefined) {
        data.music = 0;
    }
    if (data.chou === undefined) {
        data.chou = 0;
    }

    return (
        <>
            <Row
                align={'middle'}
                justify={'center'}
                style={{ marginTop: '10px' }}
            >
                <Col lg={14} md={18} sm={22} xs={23}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Card><strong>{name}与小逸的2023年度总结</strong></Card>
                        {
                            data.most_ciku!=='None' &&
                            <Card title="年度词库">
                                <Row justify={'center'} align={'middle'}>
                                    <Col lg={4} sm={4} xs={4}>
                                        <Lottie animationData={typing} loop />
                                    </Col>
                                    <Col lg={20} sm={20} xs={20}>
                                        <p>
                                            2023年，你的年度词库是：<strong>{data.most_ciku}</strong>，一共说了<strong>{data.most_ciku_time}</strong>次，想必对你来说是口头禅一般的存在吧。其实小逸还记住了，你说过{data.dieci}次叠词词！
                                        </p>
                                    </Col>
                                </Row>
                            </Card>
                        }
                        {
                            data.yunshi &&
                            <Card title="运势">
                                <Row justify={'center'} align={'middle'}>
                                    <Col lg={4} sm={4} xs={4}>
                                        <Lottie animationData={luck} loop />
                                    </Col>
                                    <Col lg={8} sm={20} xs={20}>
                                        <p>
                                            {data['1'] > data['6'] ? '大吉照耀' : '水逆退散'}，新的一年天天大吉！
                                        </p>
                                    </Col>
                                    <Col lg={3} sm={6} xs={6}>
                                        <Statistic title="运势次数" value={data.yunshi} formatter={formatter} />
                                    </Col>
                                    <Col lg={3} sm={6} xs={6}>
                                        <Statistic title="大吉" value={data['1']} formatter={formatter} />
                                    </Col>
                                    <Col lg={3} sm={6} xs={6}>
                                        <Statistic title="大凶" value={data['6']} formatter={formatter} />
                                    </Col>
                                    <Col lg={3} sm={6} xs={6}>
                                        <Statistic title="获得卡片" value={data.yunshicard} formatter={formatter} />
                                    </Col>
                                </Row>
                            </Card>
                        }
                            <Card title="日常">
                                <Row justify={'center'} align={'middle'}>
                                    <Col lg={4} sm={4} xs={4}>
                                        <Lottie animationData={time} loop />
                                    </Col>
                                    <Col lg={8} sm={20} xs={20}>
                                        <p>
                                            2023年，你对小逸说过<strong>{data.morning}</strong>次早安，<strong>{data.night}</strong>次晚安，愿你生活充实美满。
                                        </p>
                                    </Col>
                                    <Col lg={6} sm={12} xs={12}>
                                        <Statistic title="抽奖次数" value={data.chou} formatter={formatter} />
                                    </Col>
                                    <Col lg={6} sm={12} xs={12}>
                                        <Statistic title="点歌次数" value={data.music} formatter={formatter} />
                                    </Col>
                                </Row>
                            </Card>
                            {data.most_qq&&
                            <Card title="奇奇怪怪">
                                <Row justify={'center'} align={'middle'}>
                                    <Col lg={4} sm={4} xs={4}>
                                        <Lottie animationData={game} loop />
                                    </Col>
                                    <Col lg={4} sm={4} xs={4}>
                                        <Image src={`https://q1.qlogo.cn/g?b=qq&nk=${data.most_qq}&s=100`} ></Image>
                                    </Col>
                                    <Col lg={16} sm={16} xs={16}>
                                        
                                        <p>
                                            2023年，你与ta随机匹配的次数最多，有<strong>{data.most_qq_time}</strong>次。随机老婆/老公最多的一天是{data.most_date}，那天你拥有了<strong>{data.most_date_time}</strong>份缘分（
                                        </p>
                                    </Col>
                                    
                                </Row>
                            </Card>}
                    </Space>
                </Col>
            </Row>
        </>
    );
}

const PersonalResult = () => {

    const n = useNavigate();
    const handleMenu = (e: any) => {
        cookie.remove('token');
        n('/login');
    };
    useEffect(() => {
        const auth = cookie.load('token');
        if (auth === undefined) {
            n('/login');
        }
    }, []);
    return (
        <div className="App">
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
                className='bg-white'
            >
                <div className='demo-logo' />
                <Menu
                    onClick={handleMenu}
                    mode='horizontal'
                    defaultSelectedKeys={['2']}
                    items={items}
                    style={{ minWidth: '200px', justifyContent: 'flex-end' }}
                />
            </Header>
            <Result />
        </div>
    );
}

export default PersonalResult;
